module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Faith Hacker - by James 'Jim' Wilcox #JailbreakingLife","short_name":"Faith Hacker - by James 'Jim' Wilcox #JailbreakingLife","start_url":"/gatsby-starter-cv","background_color":"#fff3ee","theme_color":"#25303B","display":"minimal-ui","icon":"src/assets/neon_gorl.png","image":"https://www.faithhackerbook.com/images/neon_gorl_faith_hacker_cropped.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c034ad1b8b6593644cf3b0e2cac26f9e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-RPZZJ72T2S"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
